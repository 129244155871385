import React, { useEffect, useState } from "react";
import Navigation from "../../Navigation";

function SkinCancerClassificationApp() {
    const [htmlContent, setHtmlContent] = useState("");

    useEffect(() => {
        fetch("/html/CreatingSkinCancerClassificationapponVipas.AI.html")
            .then((response) => response.text())
            .then((html) => setHtmlContent(html))
            .catch((error) => console.error("Error loading HTML file:", error));
    }, []);
    return (
        <>
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px] h-full">
                <div
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                    className="mb-3"
                />
                 <Navigation
                        previous={"Previous: Diabetic Retinopathy Classification app on Vipas.AI"}
                        previousNav={"diabetic-retinopathy-app"}
                       next={"Next: Harnessing LLMs Based on RAG in Streamlit: Monetizing a Smart Q&A App on Vipas.AI"}
                       nextNav={"llm-based-rag-on-streamlit"}
                   />
                   </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16"></div>
            </div>
        </>
    );
}

export default SkinCancerClassificationApp;
