import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function DeployingPyTorchModel() {
  return (
  <>
     <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className="mb-3 heading">Deploying a PyTorch model on Vipas.AI</h1>
                    <div className="border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100">
                                <a
                                    href="https://utils.vipas.ai/vps-ipynb/ml_flow_pytorch/pytorch.zip"
                                    target="_blank"
                                    className="ml-3 flex items-center"
                                >
                                    <img
                                        src={downloadIcon}
                                        width={32}
                                        height={32}
                                        alt="text-summarization-download"
                                    />{" "}
                                    <span className="ml-2">
                                        Download Notebook
                                    </span>
                                </a>
                            </div>
                            <p>
                                This guide provides a step-by-step explanation
                                of implementing a simple neural network using
                                PyTorch, training it on synthetic data, logging
                                it with MLflow, and deploying it with custom
                                configurations.
                            </p>

                            <p className="text-xl font-semibold my-2">
                                Key Steps:
                            </p>
                            <ol className="list-decimal ml-6">
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Install Required Libraries
                                    </h2>
                                    <p>
                                        Essential libraries like PyTorch,
                                        MLflow, Docker, and Scikit-learn are
                                        used to develop, log, and deploy the
                                        model seamlessly.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Define the PyTorch Model
                                    </h2>
                                    <p>
                                        A basic neural network is built using
                                        PyTorch. The SimpleNN class defines an
                                        architecture with:
                                    </p>
                                    <ul className="list-disc list-inside ">
                                        <li>An input layer.</li>
                                        <li>
                                            A hidden layer with ReLU activation.
                                        </li>
                                        <li>
                                            An output layer for binary
                                            classification.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Generate Synthetic Data
                                    </h2>
                                    <p>
                                        Synthetic data is created with 1,000
                                        samples and 10 features. Labels are
                                        binary (0 or 1). The dataset is split
                                        into training (80%) and testing (20%)
                                        subsets.
                                    </p>
                                </li>

                                <li className="mb-6">
                                    <h2 className="font-semibold mb-2">
                                        Data Preprocessing
                                    </h2>
                                    <p>
                                        The data is converted into PyTorch
                                        tensors, enabling compatibility with
                                        PyTorch's data pipeline.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Model Training
                                    </h2>
                                    <p>
                                        The model is trained over 50 epochs
                                        using the Adam optimizer and
                                        cross-entropy loss. Loss values are
                                        printed at every 10th epoch.
                                    </p>
                                </li>

                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Evaluate Model Performance
                                    </h2>
                                    <p>
                                        Accuracy is calculated on the test
                                        dataset using predictions generated by
                                        the trained model.
                                    </p>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Log Model with MLflow
                                    </h2>
                                    <p>
                                        The trained model and its parameters are
                                        logged into MLflow. This includes:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Logging model architecture,
                                            parameters, and accuracy.
                                        </li>
                                        <li>
                                            Storing the trained model for later
                                            use.
                                        </li>
                                        <li>
                                            Capturing the unique run_id for
                                            deployment.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Create Deployment Configuration
                                    </h2>
                                    <p>
                                        A deployment-ready configuration is
                                        generated:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Model Settings File</strong>
                                            : Defines the runtime and URI for
                                            MLServer.
                                        </li>
                                        <li>
                                            <strong>Dockerfile</strong>:
                                            Specifies the environment and
                                            dependencies for containerizing the
                                            model.{" "}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Build and Push Docker Image
                                    </h2>
                                    <p>Using Docker:</p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            The model is containerized with the
                                            created configuration.
                                        </li>
                                        <li>
                                            Optionally, the image can be pushed
                                            to a container registry for external
                                            deployment.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Publish Model to Vipas.AI
                                    </h2>
                                    <p>Using Vipas.AI SDK:</p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            The model is published with metadata
                                            such as permissions and the Docker
                                            image tag.
                                        </li>
                                        <li>
                                            Publishing status and responses are
                                            logged.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h2 className="font-semibold mb-2">
                                        Run Predictions
                                    </h2>
                                    <p>
                                        A JSON input structure is defined, and
                                        the deployed model predicts the output
                                        for the given data:
                                    </p>
                                    <ul className="list-disc list-inside">
                                        <li>
                                            <strong>Input</strong>: A JSON
                                            object containing features.
                                        </li>
                                        <li>
                                            <strong>Output</strong>: The
                                            predicted class is displayed in a
                                            user-friendly widget interface.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <Navigation
                        previous={"Previous: Harnessing LLMs Based on RAG in Streamlit: Monetizing a Smart Q&A App on Vipas.AI"}
                        previousNav={"llm-based-rag-on-streamlit"}
                       next={"Next: Wine Quality Prediction Using ElasticNet Regression"}
                       nextNav={"wine-quality-prediction"}
                   />
                </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    {/* <p className="font-semibold my-8 ">On this page</p>
                    <ul className="border-t-2 py-4">
                        <li className="link-list ">
                            <a href="#supported-file-types" className="links">
                                Supported File Types
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#directory-structure" className="links">
                                Directory Structure
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#model-configuration" className="links">
                                Model Configuration
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#summary-workflow" className="links">
                                Summary Workflow
                            </a>
                        </li>
                        <li className="link-list ">
                            <a href="#examples" className="links">
                                Examples
                            </a>
                        </li>
                    </ul> */}
                </div>
            </div>
  </>
  )
}

export default DeployingPyTorchModel
