import React, { useEffect, useState } from "react";
import Navigation from "../../Navigation";

function BoneFractureDetationStreamlit() {
    const [htmlContent, setHtmlContent] = useState("");

    useEffect(() => {
        fetch("/html/CreatingBoneFractureDetectionusingStreamliton.html")
            .then((response) => response.text())
            .then((html) => setHtmlContent(html))
            .catch((error) => console.error("Error loading HTML file:", error));
    }, []);
    return (
        <>
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px] h-full">
                <div className="mb-3"
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                    
                />
                    <Navigation
                        previous={"Previous: Deploying a Fine-Tuned YOLO Model for Bone Fracture Detection on Vipas.AI"}
                        previousNav={"fine-tuned-yolo-model-bone-fracture-detection"}
                       next={"Next: Diabetic Retinopathy Classification app on Vipas.AI"}
                       nextNav={"diabetic-retinopathy-app"}
                   /> 
                    </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16"></div>
            </div>
        </>
    );
}

export default BoneFractureDetationStreamlit;
