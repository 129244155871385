import React, { useEffect, useState } from "react";
import Navigation from "../../Navigation";

function FinetunedYOLOModelBoneFracture() {
    const [htmlContent, setHtmlContent] = useState("");

    useEffect(() => {
        fetch("/html/DeployingaFineTunedYOLOModelforBoneFractureDe.html")
            .then((response) => response.text())
            .then((html) => setHtmlContent(html))
            .catch((error) => console.error("Error loading HTML file:", error));
    }, []);
    return (
        <>
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px] h-full">
                <div
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                    className="mb-3"
                />
                 <Navigation
                        previous={"Previous: Deploying LLaMA Model Using MLflow on Vipas.AI: A Comprehensive Guide"}
                        previousNav={"deploying-llama-using-mlflow"}
                       next={"Next: Bone Fracture Detection Using Object Detection Models: A Technical Deep Dive"}
                       nextNav={"bone-fracture-dectection-streamlit"}
                   /> 
                   </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16"></div>
            </div>
        </>
    );
}

export default FinetunedYOLOModelBoneFracture;
