import React from 'react'
import downloadIcon from "../../../assets/images/download.png";
import Navigation from '../../Navigation';

function FlowerClassPrediction() {
  return (
    <>
    <div className="flex my-8 relative">
               <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                   <h1 className="mb-3 heading">Flower Class Prediction</h1>
                   <div className='border rounded-[5px] w-[250px] p-2 shadow-md my-2 hover:bg-gray-100'>
                <a href="https://utils.vipas.ai/vps-ipynb/flower_class_prediction/flower_class_pt.zip" target="_blank" className='ml-3 flex items-center'><img src={downloadIcon} width={32} height={32} alt="text-summarization-download" /> <span className='ml-2'>Download Notebook</span></a>
              </div>
              <div className='my-4'>
                <h2><strong>Flower class prediction</strong> is a machine learning project aimed at classifying images of flowers into different categories using a deep learning model built with PyTorch.</h2>
                <p>This guide provides an end-to-end implementation for training and deploying a flower classification model using PyTorch, covering dataset preparation, model training, and deployment.</p>
              </div>

              <ol class="list-decimal ml-6">
                <li>
                  <h2 class="font-semibold mb-2">Setup</h2>
                  <ul class="list-disc list-inside">
                    <li>Install essential libraries like PyTorch, torchvision, scipy, and others for data handling, model training, and deployment. Initialize the computation device (<code>CPU</code>) and define transformations for data augmentation and normalization.</li>
                  </ul>
                </li>

                <li >
                  <h2 class="font-semibold mb-2">Dataset Handling</h2>
                  <ul class="list-disc list-inside ">
                    <li>Download and preprocess the Flowers102 dataset, subset it for demonstration, and create data loaders for batch processing. The dataset is augmented with transformations for resizing, normalization, and random flips.</li>
                  </ul>
                </li>
                <li>
                  <h2 class="font-semibold mb-2">Model Architecture</h2>
                  <ul class="list-disc list-inside ">
                    <li>Build a simple neural network with fully connected layers for classifying 102 flower classes. Define the loss function (<code>CrossEntropyLoss</code>) and optimizer (e.g., <code>Adam</code>) with configurable hyperparameters like learning rate.</li>
                  </ul>
                </li>

                <li class="mb-6">
                  <h2 class="font-semibold mb-2">Training</h2>
                  <ul class="list-disc list-inside">
                    <li>Implement a training loop with backpropagation, loss computation, and parameter updates. Include validation steps to monitor accuracy and performance after each epoch.</li>
                  </ul>
                </li>

                <li >
                  <h2 class="font-semibold mb-2">Prediction and Deployment</h2>
                  <ul class="list-disc list-inside">
                    <li>Provide functionality to preprocess and classify images using the trained model. Archive the model for deployment and publish it on the Vipas.AI platform for broader access.</li>
                  </ul>
                </li>

                <li>
                  <h2 class="font-semibold mb-2">API Integration</h2>
                  <ul class="list-disc list-inside">
                    <li> Include features for encoding input images in Base64 format and interacting with Vipas.AI's prediction API for inference.</li>
                  </ul>
                </li>
              </ol>
              <div className='my-4'>
                  <h3 className='font-semibold text-xl'>
                  Steps to Run the Notebook
                  </h3>
                  <ol className='list-decimal ml-6'>
                    <li> <strong>Download the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Obtain the notebook file and its associated resources as a zip file.</li>
                        </ul>
                    </li>

                    <li> <strong>Extract the Zip</strong>
                        <ul className='list-disc ml-3'>
                          <li>Extract the contents of the zip file using any file extraction tool (e.g., WinRAR, 7-Zip, or the built-in extraction tool in your operating system).</li>
                        </ul>
                    </li>

                    <li> <strong>Upload Files to Google Colab</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open <a href="https://colab.research.google.com/" target="_blank">Google Colab</a> in your browser and sign in to your account.</li>
                          <li>In "File" menu tab, click on "Upload notebook button" and select "upload".</li>
                          <li>Upload all the extracted files, including the <code>.ipynb</code> notebook file, to the Colab environment.</li>
                        </ul>
                    </li>
                    <li> <strong>Run the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Open the uploaded <code>.ipynb</code> notebook file in Google Colab.</li>
                          <li>Ensure all dependencies (like PyTorch, torchvision, etc.) are installed by running the corresponding cells. If any libraries are missing, you may need to install them using <code>!pip install</code>.</li>
                          <li>Run all the cells sequentially or click on <code>Runtime</code> and <code>Run all</code> to execute the entire notebook.</li>
                        </ul>
                    </li>
                    <li> <strong>Interact with the Notebook</strong>
                        <ul className='list-disc ml-3'>
                          <li>Follow any instructions provided in the notebook, such as selecting hyperparameters or running specific interactive widgets.</li>
                          <li>View the outputs, logs, and predictions as the notebook executes.</li>
                        </ul>
                    </li>
                  </ol>
              </div>
              <Navigation
                       previous={"Previous: Text Summarization"} previousNav={"text-summarization"}
                       next={"Next: Stock Prediction"}
                       nextNav={"stock-prediction"}
                   />  
               </div>
               <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                   {/* <p className="font-semibold my-8 ">On this page</p>
                   <ul className="border-t-2 py-4">
                       <li className="link-list ">
                           <a href="#supported-file-types" className="links">
                               Supported File Types
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#directory-structure" className="links">
                               Directory Structure
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#model-configuration" className="links">
                               Model Configuration
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#summary-workflow" className="links">
                               Summary Workflow
                           </a>
                       </li>
                       <li className="link-list ">
                           <a href="#examples" className="links">
                               Examples
                           </a>
                       </li>
                   </ul> */}
               </div>
           </div>
   </>
  )
}

export default FlowerClassPrediction
