import React, { useEffect, useState } from "react";
import Navigation from "../../Navigation";

function DiabeticRetinopathyApp() {
    const [htmlContent, setHtmlContent] = useState("");

    useEffect(() => {
        fetch("/html/CreatingDiabeticRetinopathyClassificationappo.html")
            .then((response) => response.text())
            .then((html) => setHtmlContent(html))
            .catch((error) => console.error("Error loading HTML file:", error));
    }, []);
    return (
        <>
            <div className="flex my-8 relative">
                <div 
                    className="w-[100%] xl:w-[66.66%] px-[24px] h-full">
                <div className="mb-3"
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />

                <Navigation
                        previous={"Previous: Bone Fracture Detection Using Object Detection Models: A Technical Deep Dive"}
                        previousNav={"bone-fracture-dectection-streamlit"}
                       next={"Next: Creating a Skin Cancer Classification App on Vipas.AI"}
                       nextNav={"skin-cancer-classsificaion-app"}
                   />
                </div>
                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16"></div>
            </div>
        </>
    );
}

export default DiabeticRetinopathyApp;
